import { FC } from 'react';
import { Card, CardContent } from '@mui/material';
import { Title, Button } from 'react-admin';


import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  style: any;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function HelpZoomSetup() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Title title="Zoom Setup Guide" />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="Help section tabs" sx={{ '& .MuiTabs-flexContainer': { flexDirection: {xs: 'column', sm: 'row'} } }}>
          { /*
          <Tab label="Step 1 - Zoom Setup for Recording" {...a11yProps(0)} />
          <Tab label="Step 2 - Download Zoom Audio and Meeting Data" sx={{ maxWidth: 500 }} {...a11yProps(1)} />
          */ }
          <Tab label="Zoom Setup and Meeting Upload" {...a11yProps(0)} />
        </Tabs>
      </Box>
      { /*
      <CustomTabPanel value={value} index={0} style={{maxWidth: "1000px"}}>
        <div style={{position: "relative", paddingBottom: "52.96875%", height: "0"}}>
          <iframe 
            src="https://www.loom.com/embed/7781a518cdd2443d8a70068fb2d79ddb?sid=71928e4d-f76b-4d95-a075-f7c2a3b1f4c4?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"  
            allowFullScreen 
            style={{border: "0px", position: "absolute", top: "0", left: "0", width: "100%", height: "100%"}}
          ></iframe>
        </div>
        <p><Button label="Continue to Step 2" onClick={() => setValue(value+1)} variant="contained" startIcon={<ArrowForwardIcon />} /> to learn how to download the files you'll need to for uploading a meeting to Yakbit.</p>
        <hr />
        <p>In this video, I will guide you through the process of setting up Zoom for recording.</p>
        <p>First, go to your home page or sign in to Zoom.</p>
        <p>Then, scroll down to settings on the left-hand side and select recording.</p>
        <p>Choose cloud recording and make sure to click all the necessary boxes, including record active speaker, record gallery view with shared screen, and record audio only.</p>
        <p>Don't forget to tick the options to save chat messages, enable advanced cloud recording settings, and allow cloud recording sharing.</p>
        <p>I will also show you how to add timestamps, display participants' names, record thumbnails, optimize recordings for third-party video editors, and save closed captions as a BTT file.</p>
        <p>Watch the video to learn all the steps and get your Zoom recording set up efficiently.</p>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} style={{maxWidth: "1000px"}}>
        <div style={{position: "relative", paddingBottom: "52.96875%", height: "0"}}>
          <iframe 
            src="https://www.loom.com/embed/76e54af1c3834c27a9b31cf6f2373177?sid=24bb91f8-c2f0-4b65-9963-191b29582191i?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"  
            allowFullScreen 
            style={{border: "0px", position: "absolute", top: "0", left: "0", width: "100%", height: "100%"}}
          ></iframe>
        </div>
        <p><Button label="Go back to Step 1" onClick={() => setValue(value-1)} variant="contained" startIcon={<ArrowBackIcon />} /> to learn how to setup Zoom for Yakbit.</p>
        <hr />
        <p>Hey there! In this Loom, I'll show you how to analyze meeting data and download individual audio files.</p>
        <p> First, head over to Zoom and navigate to the recordings section under the settings tab.</p>
        <p> From there, go to the cloud report recordings and select the desired meeting.</p>
        <p> Next, download the audio files of each participant.</p>
        <p> Don't forget to check the usage reports for the meeting you want to analyze.</p>
        <p> Once you have all the necessary files, you can start analyzing the data and creating your article.</p>
        <p> Action requested: Please follow the steps and have the audio files ready for upload.</p>
        <p> Let's dive in!</p>
      </CustomTabPanel>
      */ }
      <CustomTabPanel value={value} index={0} style={{maxWidth: "1000px"}}>
        <h3>Adding the App</h3>
        <ol>
            <li>Click on 'Settings' on the left menu.</li>
            <li>Click 'Connect Zoom Account' - this will take you to Zoom's website.</li>
            <li>Follow the instructions provided to authorise the Yakbit app for you Zoom account</li>
            <li>You will then be redirected back to Yakbit</li>
        </ol>

        <h3>Usage</h3>
        <ol>
            <li>Ensure you have added the Yakbit app to your Zoom account - see 'Adding the App' above</li>
            <li>Start a Zoom meeting</li>
            <li>Copy the invite link</li>
            <li>Return to the Yakbit webapp</li>
            <li>Click on 'Meetings' on the left menu.</li>
            <li>Click on the 'Add Meeting Link' button.</li>
            <li>Enter the meeting link into the box provided and hit 'Save'</li>
            <li>The Yakbit meeting bot will then join your meeting, and after a few seconds it will start recording the meeting - you will hear the notice announcing that the meeting is being recorded.</li>
            <li>Once you end the meeting, the meeting will be analysed and you can then book a coching session to discuss the results.</li>
        </ol>

        <h3>Removing the App</h3>

        <ol>
            <li>Login to your Zoom Account and navigate to the Zoom App Marketplace.</li>
            <li>Click Manage >> Added Apps or search for the "Yakbit" app.</li>
            <li>Click the "Yakbit" app.</li>
            <li>Click Remove.</li>
        </ol>

        <h3>Support</h3>
        <p>If you encounter any issues <span>Please email <a href="mailto:support@yakbit.ai">support@yakbit.ai</a> for support.</span></p> 
      </CustomTabPanel>
    </Box>
  );
}
